import React from 'react'
import { Link } from 'gatsby'
import profileFaber from '../images/profile-faber.png'
import profileJTenney from '../images/profile-tenney.jpeg'
import profileJohnnie from '../images/profile-johnnie.jpeg'
import profilePereira from '../images/profile-pereira.jpeg'
import profileJeffElmer from '../images/profile-elmer.jpeg'
import profilePaulBell from '../images/profile-bell.jpeg'
import profileMournes from '../images/profile-mournes.jpeg'
import profileWells from '../images/profile-sharlene.jpeg'
import profileDuvall from '../images/profile-mduval.jpeg'
import profileFadoul from '../images/profile-fadoul.jpeg'
import profileNathan from '../images/profile-nathan.jpeg'
import profileGordon from '../images/profile-gordon-son.jpeg'
import profileRosaria from '../images/profile-rosaria.jpeg'
import profileAlen from '../images/profile-alen.jpeg'

import Layout from '../components/layout'

const AdvisorsPage = () => (
  <Layout>
    <h2 className="major">Board of Advisors</h2>
    <h3 id="Bell">Paul Bell, R.N., B.S.N</h3>
    <img width="20%" src={profilePaulBell} alt="" />
    <p>
      Bell, Paul E. (RN/BSN), after serving 22 years in the Navy, retired as an Officer with Subspecialty Codes in
      Emergency/Trauma Care, Critical Care, and Ortho/Neuro Clinical Specialist. He graduated from the University of
      Southern Mississippi in Hattiesburg. His status as an “8404” Combat Corpsman and as a certified Clinical Corpsmen
      to provide autonomous care under clinical guidance will be invaluable to the foundation. He has excelled in
      Administration, Clinical Assessment, Critical Pathway Development, Clinical Studies, and Direct Patient Care.
    </p>
    <h3 id="Gordon-son">Gordon Duval (Son), M.D.</h3>
    <img width="20%" src={profileGordon} alt="" />
    <p>
      Gordon Duval, M.D. Member of the Advisory Board, received his Bachelor’s degree from Brigham Young University in Nutritional Science, and his Doctor of Osteopathic Medicines from Lake Erie College, after which he did his residency and Internship in the University of Missouri Women’s and Children’s Hospital. He is certified by the American Board of Pediatrics. At present he is a Pediatrician at the Mountain West Medical Center & Wellsprings Pediatrics. He has served in diverse positions, both professionally and volunteer, always seeking the wellbeing of children.
    </p>
    <h3 id="Duval">Michael Duvall, J.D.</h3>
    <img width="20%" src={profileDuvall} alt="" />
    <p>
      Michael Duval, J.D., a graduate in energy, natural resources, and not-for-profit organizations at Notre Dame Law
      School, has worked extensively with various refugee support groups, assisting refugees on three different
      continents--mostly within legal spheres. He is a member of the Utah Bar.
    </p>
    <h3 id="Elmer">Jeffrey Elmer, M.D.</h3>
    <img width="20%" src={profileJeffElmer} alt="" />
    <p>
      Dr. Jeffrey Elmer, MD is a pulmonologist in Spokane Valley, WA and has been practicing for 33 years. He graduated
      from University of Texas medical school in 1982. He is board certified and specializes in pulmonary disease,
      critical care medicine, and internal medicine. He did his internship and residency in the Bexar County Hospital
      and a fellowship at the University of Utah. He is affiliated to the Providence Holy Family Hospital and the
      Providence Sacred Heart Medical Center and Children Hospital. He is fluent in English and Spanish. He served a
      mission in Argentina and was Mission President in Peru.
    </p>
    <h3 id="Faber">Daniel Faber, M.D.</h3>
    <img width="20%" src={profileFaber} alt="" />
    <p>
      Dr. Faber received his Bachelor’s degree from Brigham Young University graduating magna cum laude. He graduated
      from medical school at the University of California, San Diego. <br />
      Dr. Faber is board certified by the American Board of Anesthesiology with a special Certification in Pain
      Medicine. He is also board certified by the American Board of Pain Medicine and the American Board of
      Interventional Pain Physicians. Dr. Faber has been practicing medicine since 1983 and in Utah Valley since 1998.
      <br />
      Dr. Faber is fluent in both English and Spanish.
    </p>
    <h3 id="Faber">Alen, Gibbons, B.A., M.B.A.</h3>
    <img width="20%" src={profileAlen} alt="" />
    <p>
      Alen Gibbons is a Finance and Information Executive in the financial services industry.  He has over 30 years of
      experience in financial management, corporate strategy, risk controls, and information governance.  Alen holds an 
      MBA in both Finance and Administrative Organization from the University of Washington School of Business, and a 
      BA in Economics from Brigham Young University.  He served a mission in the Argentina Buenos Aires North Mission and 
      speaks fluent Spanish.  He served for over ten years as an Editorial Board Member for the Journal of Performance Management.
    </p>
    <h3 id="Pereira">Augusto Pereira Gonzalez, M.D.</h3>
    <img width="20%" src={profilePereira} alt="" />
    <p>
      Pereira Gonzalez, Augusto, (M.D., Surgeon, Pediatric Oncology, Medical Admin.) Member of the Board of Advisors, is
      the Head of Pediatric Oncology of the J.M. de los Rios Children Hospital of Venezuela, Director of Courses for
      Pediatric Oncology, and President of the Foundation of the same hospital. Besides and extensive career in
      Venezuela and internationally, he did a fellowship at the Department of Hematology and Oncology at the St. Jude
      Hospital in Memphis Tennessee.
    </p>
    <h3 id="Gwilliams">Nathan Gwilliams, MBA, BGS</h3>
    <img width="20%" src={profileNathan} alt="" />
    <p>
      Nathan Gwilliams, MBA, BGS, Member of the Board of Advisors, Founder and CEO of Monetization Partners, is a successful
      serial entrepreneur who has created several digital ventures. He has created a solid approach for growing successful
      ventures in the digital era-- that he has expounded in TEDtalkx—and that he has used, for instance, to grow social
      followership from 110K to 130 million in a few months for one venture.  Based on his achievements, he was awarded
      the "Best of the Decade" award by the BYU Center for Entrepreneurship.  He is also a dedicated humanitarian, having
      served in non-profit organizations.
    </p>
    <h3 id="Johnnie">Johnnie Harris, B.S.</h3>
    <img width="20%" src={profileJohnnie} alt="" />
    <p>
      Johnnie Harris was born in Utah and raised in South America, primarily in Uruguay, Argentina and Peru. He received
      a Bachelors of Science degree in Biology from the University of Utah in 1994. He has been working in Software
      Engineering within the Healthcare and Financial industries. Johnnie has architected, designed and implemented
      creative and interesting solutions in both the clinical and financial domains.
    </p>
    <h3 id="Fadoul">Fadoul Mazzawi, CEO</h3>
    <img width="20%" src={profileFadoul} alt="" />
    <p>
      Fadoul Mazzawi, the CEO of the Mazzawi Advertising Group, a leading marketing, advertising and digital agency located
      in Israel and specializing in the Arab speakers in Israel, West Bank, and MENA, he founded in 1995. The group's clients
      are International companies and NGO's that include the Strauss Group, Mercantile Bank, Samsung, Reckitt Benckiser (health),
      Ikea, Bezeq, Nestlé, Sano Group, Azrieli Group, The Israeli Ministry of Health and more. He serves as a chairman of the board,
      director and partner of companies and startups in Israel and the UK  and a board member in the AMCHAM- Israel America chamber
      of commerce.  He serves as Chairman of the board of Trustees and a member of the management board of Yezreel Valley Academic
      College for over 9 years. He has been a director in the management board of the Israeli Advertising Agencies and the President
      of the Central Office for Art, Advertising and PR in the European Economic Chamber EEIG, Brussels since 2009.  He is a Visiting
      lecturer in several universities for Advertising and marketing to the ME and Arab Markets and a Counselor to leading institutions
      in marketing to Arab markets.  Fadoul is a humanitarian who serves as co-chairman for The Association for Joint Israeli Leadership.
      He is a well-known calligrapher in Arabic and Hebrew, and since 1995 he has received International awards in Calligraphy and design.
      <br />
      Fadoul is fluent in English, Arab, Hebrew, and German.
    </p>
    <h3 id="Mournes">Bill Mournes, B.S.</h3>
    <img width="20%" src={profileMournes} alt="" />
    <p>
      William Mournes has thirty-one years of experience in energy investments and real estate development both in the
      USA and internationally. He is an international consultant on global energy policy and transactions as well as
      related geopolitical issues and government security, and has extensive experience in identifying and acquiring
      foreign capital for deployment and investment in global energy projects with a specialty currently in Latin
      America. He has a Bachelor of Science Degree in Political Science from Seton Hall University with emphasis in
      international commerce and natural resources. His international contact and experience in international matters
      make his advice crucial for the foundation.
    </p>
    <h3 id="Rosaria">Rosaria Martone, J.D.</h3>
    <img width="20%" src={profileRosaria} alt="" />
    <p>
      Dr. Rosaria Martone is a lawyer, geographer, and international consultant, deeply acquainted with the realities
      of all countries in Latin America and their governments and institutions, thus able to guide the foundation in
      its efforts to bring assistance to children and families.  She founded and now presides over
      FUNDACION DEPORTIVA FUTBOL VIRTUAL, an organization that helps children learn to respect and compete in a healthy
      environment, and that includes each one of them, poor or rich, of any race, belief, and age, allowing for
      universal inclusion, and equality, helping them to develop skills, and giving them access to knowledge and
      technologies.
    </p>
    <h3 id="Tenney">Dennis Tenney, M.B.A.</h3>
    <img width="20%" src={profileJTenney} alt="" />
    <p>
      Dennis Tenney was born and raised in Arizona. After serving a mission in Ecuador and Peru, he obtained a Bachelor
      in Latin American Studies and Political Science, with an emphasis in International business, and a Master’s Degree
      in International Management, with honors at Thunderbird. Dennis is currently the Executive Director of the
      Inter-Mountain Chapter of the U.S. Mexico Chamber, and a business consultant to multinational corporations, in
      areas such as power generation, minerals extraction and nutritional supplements. He has also been active in
      charitable organizations including Board Chairman of the South Valley Sanctuary, Executive Director for the OG
      Cares Foundation, and was a Paul Harris Fellow at Rotary International.
    </p>
    <h3 id="Wells">Sharlene Wells Hawkes</h3>
    <img width="20%" src={profileWells} alt="" />
    <p>
      Sharlene Wells Hawkes has distinguished herself as a great humanitarian in aiding the veterans of the U.S. Armed
      Forces and preserving the memories of their devotion; also showing through her achievements that she cares for
      those who have defended the U.S. A. and its principles, completing over 400 historical projects throughout the
      military and Veteran Affairs community; and also for her generosity in delivering over one million copies of books
      and documentaries to veterans across the 50 states of the Union.
    </p>
    <Link to="/">Go back to the homepage</Link>
  </Layout>
)

export default AdvisorsPage
